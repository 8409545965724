import React, { useState, useRef, useEffect } from 'react';

import Submit from '../../global/Submit';
import '../../../styles/global/Select.scss';

function DropDown(props) {
  const current = props.current;
  let btns = current.action.options;
  const [open, setOpen] = useState(false);
  if (!btns) {
    btns = current.action;
  }
  let btn = [];
  for (const key in btns) {
    btn.push({
      text: btns[key].text,
      value: btns[key].value,
      next_q: btns[key].next_q,
    });
  }
  const [selected, setSelected] = useState(btn[0]);
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    if (clickedOutside) {
      setOpen(false);
    }
  }, [clickedOutside]);
  return (
    <div
      className="buttonContainer isSelect"
      ref={myRef}
      onClick={handleClickInside}
    >
      <div className="select">
        <select className="s-hidden">
          {btn.map((o, ind) => {
            return (
              <option key={`btn_${ind}`} className="selectopt" value={o.value}>
                {o.text}
              </option>
            );
          })}
        </select>
        <div
          className={!open ? 'styledSelect' : 'styledSelect active'}
          onClick={() => {
            setOpen(true);
          }}
        >
          {selected.text}
        </div>
        <ul className="options" style={open ? { display: 'block' } : {}}>
          {btn.map((o, ind) => {
            return (
              <li
                key={`btn_ul_${ind}`}
                className={
                  selected.value === o.value ? 'selected noselect' : 'noselect'
                }
                onClick={() => {
                  if (selected.value !== o.value) {
                    setSelected(o);
                  }
                  setOpen(false);
                }}
                rel={o.value}
              >
                {o.text}
                {selected.value === o.value && <i className="fas fa-check" />}
              </li>
            );
          })}
        </ul>
      </div>
      <Submit
        className="square"
        //style={msg.length === 0 ? { opacity: 0.4 } : {}}
        onClick={() => {
          props.sendResponse(selected, selected.text, current);
        }}
      >
        <img src={require('../../../assets/send.svg').default} />
      </Submit>
    </div>
  );
}
export default DropDown;
