import {
  SET_SURVEY,
  SET_LANG,
  SET_APP_READY,
  SET_CURRENT,
  ADD_QUESTION,
  ADD_ANSWER,
  UPDATE_QUESTION_TRIES,
  SET_THINKING,
  SET_SEEN_INTRO,
  ADD_CUSTOM_INTRO,
  ADD_END_QUESTION,
  SET_USERID,
  SET_UID_SAVED,
  SET_OVERLAY,
} from '../actions/appActions';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  survey: {},
  fromLang: 'sv',
  toLang: 'sv',
  userid: 'local_' + uuidv4(),
  appReady: false,
  uidSaved:false,
  currentQuestion: null,
  questions: [],
  answers: [],
  thinking: false,
  seenIntro: false,
  hide_intro: false,
  customIntro: false,
  overlay:false,
  endQuestion: false,
};
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SURVEY: {
      let survey = { ...state.survey };

      survey = {
        nr: parseInt(action.data.nr, 10),
        name: action.data.survey,
        start_id: action.data.start_id,
        hide_intro: parseInt(action.data.hide_intro, 10),
        intro_text: action.data.intro_text,
        intro_button: action.data.intro_button,
        intro_button_delay: parseInt(action.data.intro_button_delay, 10),
        cint_id: action.data.cint_id,
        warnings: parseInt(action.data.warnings, 10),
        seenIntro: parseInt(action.data.hide_intro, 10) === 0 ? false : true,
      };
      return { ...state, survey };
    }
    case SET_USERID: {
      let userid = { ...state.userid };
      userid = action.data;
      return { ...state, userid };
    }
    case SET_THINKING: {
      let thinking = { ...state.thinking };
      thinking = action.data;
      return { ...state, thinking };
    }

    case SET_CURRENT: {
      let currentQuestion = { ...state.currentQuestion };
      currentQuestion = action.id;
      return { ...state, currentQuestion };
    }
    case SET_SEEN_INTRO: {
      let seenIntro = { ...state.seenIntro };
      seenIntro = action.data;
      return { ...state, seenIntro };
    }
    case ADD_CUSTOM_INTRO: {
      let customIntro = { ...state.customIntro };
      customIntro = action.data;
      return { ...state, customIntro };
    }
    case ADD_END_QUESTION: {
      let endQuestion = { ...state.endQuestion };
      endQuestion = action.data;
      return { ...state, endQuestion };
    }
    case ADD_ANSWER: {
      let answers = [...state.answers];
      let data = action.data;
      data['ts'] = new Date().getTime();
      data['type'] = 'resp';
      answers.push(data);
      return { ...state, answers };
    }
    case ADD_QUESTION: {
      const questions = { ...state.questions };
      let data = action.data;
      data['ts'] = new Date().getTime();
      data['tries'] = 0;
      questions[data.id] = data;
      return { ...state, questions };
    }
    case UPDATE_QUESTION_TRIES: {
      const questions = { ...state.questions };
      questions[action.id].tries = questions[action.id].tries + 1;
      return { ...state, questions };
    }
    case SET_LANG: {
      let ns = { ...state };
      ns.fromLang = action.data.fromLang;
      ns.toLang = action.data.toLang;
      return { ...state, ...ns };
    }
    case SET_UID_SAVED:{
      let uidSaved = { ...state.uidSaved };
      uidSaved = true;
      return { ...state, uidSaved };
    }
    case SET_OVERLAY:{
      let overlay = action.data;
      return { ...state, overlay };
    }
    case SET_APP_READY: {
      let appReady = { ...state.appReady };
      appReady = action.appReady;

      return { ...state, appReady };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
