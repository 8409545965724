import React from 'react';

import InputType from './InputType';
import ButtonsType from './Buttons';
import ButtonNInput from './ButtonNInput';

import DropDown from './DropDown';
import Multi from './Multi';
import SlideModule from './SlideModule';
import QuizModule from './QuizModule';

const ControlItem = (props) => {
  const cType = props.current.type;
  //info
  //options
  //multi
  if (cType === 'text') {
    return <InputType {...props} />;
  }
  if (cType === 'buttons') {
    return <ButtonsType {...props} />;
  }
  if (cType === 'dropdown') {
    return <DropDown {...props} />;
  }
  if (cType === 'multi') {
    return <Multi {...props} />;
  }
  if (cType === 'buttons_free') {
    return <ButtonNInput {...props} />;
  }
  if (cType === 'slide') {
    return <SlideModule {...props} />;
  }
  if (cType === 'quiz') {
    return <QuizModule {...props} />;
  }

  return <div className="nada" />;
};

export default ControlItem;
