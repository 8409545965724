import { connect } from 'react-redux';
import { setSeenIntro, getNext } from '../../data/actions/appActions';
import IntroView from '../../components/intro/IntroView';

const mapStateToProps = (state, ownProps) => {
  let found =
    Object.keys(state.thread.thread).length > 0 || state.app.customIntro;

  return {
    isReady: found,
    questions: state.app.questions,
    currQ: state.app.currentQuestion,
    survey: state.app.survey,
    custom: state.app.customIntro,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getNext: (nr) => {
      return dispatch(getNext(nr));
    },
    setSeen: () => {
      return dispatch(setSeenIntro(true));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroView);
