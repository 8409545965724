import { connect } from 'react-redux';
import { reverse } from 'lodash';
import ChatView from '../../components/chat/ChatView';

const mapStateToProps = (state, ownProps) => {
  let ques = [];

  Object.keys(state.thread.thread).map((key, index) => {
    ques.push(state.thread.thread[key]);
    return null;
  });
  ques = reverse(ques);
  return {
    thread: ques,
    thinking: state.app.thinking,
    end: state.app.endQuestion,
    survey: state.app.survey,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatView);
