const CONFIG = {
  host: 'elli.dev.se',
  host_endpoint: '/api/animal',
  host_survey: '/api/survey',
  host_cint: '/api/cint',
  host_quiz: '/api/quiz',
  host_google: '/api/google',
  requestSurvey: '/getsurvey/',
  requestQuestion: '/getcard/',
  submitGoogle: '/googlesearch/',
  saveUser: '/saveuser/',
  spellCheck: '/spellcheck',
  submitAnalyze: '/analyze/',
  submitAnswer: '/saveanswer/',
  quizSave: '/answerQuiz',
  setStatus: '/setStatus',
  cintSave: '/setCintStatus',
  cintHost5: 'https://s.cint.com/Survey/Complete?ProjectToken=',
  cintHost2: 'https://s.cint.com/Survey/EarlyScreenOut?ProjectToken=',
};
export default CONFIG;
