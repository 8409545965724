import axios from 'axios';
import CONFIG from '../config/config.js';
import qs from 'qs';
axios.defaults.withCredentials = true;
export function getSurvey(id) {
  return get(CONFIG.requestSurvey + id);
}

export function submitCint(uid, cint_id) {
  return get(CONFIG.cintSave + '/' + uid + '/' + cint_id, CONFIG.host_cint);
}

// export function requestAnalyze(text, id) {
//   return get(CONFIG.requestAnalyze + text + '/' + id, CONFIG.host_google);
// }

export function saveUser(uid, sid) {
  return get(CONFIG.saveUser + uid + '/started/' + sid);
}

export function submitGoogle(next_q, intent, value, tries) {
  let data = { input: value, tries };
  return post(CONFIG.submitGoogle + next_q + '/' + intent, data);
}

export function submitQuiz(next_q, value, tries) {
  let data = { answer: value, tries };
  return post(CONFIG.quizSave + '/' + next_q, data, CONFIG.host_quiz);
}

export function submitAnalyze(next_q, intent, value, tries) {
  let data = { input: value, tries };
  return post(
    CONFIG.submitAnalyze + next_q + '/' + intent,
    data,
    CONFIG.host_google
  );
}

export function submitStatus(data) {
  return post(CONFIG.setStatus, data, CONFIG.host_survey);
}

export function spellCheck(data) {
  return post(CONFIG.spellCheck, data, CONFIG.host_google);
}

export function getNextQuestion(sname, qNr, toLang, fromLang) {
  return get(
    CONFIG.requestQuestion + sname + '/' + qNr + '/' + toLang + '/' + fromLang
  );
}

export function submitResponseQuiz(qNr, value, uid, sid) {
  let data = { answer: value, uid, sid };
  return post(CONFIG.quizSave, data, CONFIG.host_google);
}
export function submitResponse(qNr, value, uid, sid) {
  let data = { answer: value, uid, sid };
  return post(CONFIG.submitAnswer + qNr, data);
}

const post = (endpoint, data, host_endpoint = CONFIG.host_endpoint) => {
  let host = window.location.hostname;
  if (host === 'devlocal.se' || host === 'localhost') {
    host = CONFIG.host;
  }
  host = window.location.protocol + '//' + host + host_endpoint;
  //console.log(host + endpoint);
  return axios
    .post(host + endpoint, qs.stringify(data, { parseArrays: false }))
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};

const get = (endpoint, host_endpoint = CONFIG.host_endpoint) => {
  let myHost = window.location.hostname;
  if (myHost === 'devlocal.se' || myHost === 'localhost') {
    myHost = CONFIG.host;
  }
  let host = window.location.protocol + '//' + myHost + host_endpoint;

  return axios
    .get(host + endpoint)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
};

export function exists(url) {
  return axios
    .head(url)
    .then((res) => {
      if (res.status === 200) {
        return { status: true, url };
      }
      return { status: false, url };
    })
    .catch((err) => {
      return { status: false, url };
    });
}
