import React from 'react';
import { withRouter } from 'react-router-dom';
import ControlItem from './modules';

function ControllView(props) {
  if (props.end) {
    return null;
  }

  if (!props.current) {
    return (
      <div className="inputholder">
        <div className="nada" />
      </div>
    );
  }
  return (
    <div className="inputholder">
      <ControlItem {...props} />
    </div>
  );
}
export default withRouter(ControllView);
