import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import angleup from '../../../assets/angleUp.png';
import { shuffle, has } from 'lodash';
import Button from '../../global/Button';
import Submit from '../../global/Submit';

function MultiSelect(props) {
  const current = props.current;
  let btns = current.action.multi;
  const [btn, setBtn] = useState([]);
  const [sel, setSel] = useState(0);
  const [nextQ, setNextQ] = useState(false);

  useEffect(() => {
    let btnHolder = [];
    if (!btns) {
      btns = current.action;
    }

    for (const key in btns) {
      if (key !== 'next_q') {
        btnHolder.push({
          text: btns[key].text,
          value: btns[key].value,
          selected: false,
        });
      }
    }

    if (has(current.action, 'next_q')) {
      setNextQ(current.action.next_q);
    }

    if (current.action) {
      if (current.action.shuffle && current.action.shuffle === 1) {
        btnHolder = shuffle(btnHolder);
      }
    }
    setBtn(btnHolder);
  }, []);

  const selected = (item, ind) => {
    let b = [...btn];
    b[ind].selected = !b[ind].selected;
    if (b[ind].selected) {
      setSel(sel + 1);
    } else {
      setSel(sel - 1);
    }
    setBtn(b);
  };

  return (
    <div className="buttonContainer">
      {btn.map((b, ind) => {
        return (
          <Button
            key={`btn_${ind}`}
            className={
              b.selected ? 'option selected noselect' : 'option noselect'
            }
            onClick={() => {
              selected(b, ind);
            }}
          >
            <p className="text">{b.text}</p>
            {b.subtext && <p className="subtext">{b.subtext}</p>}
          </Button>
        );
      })}
      <Submit
        className="square"
        style={sel === 0 ? { opacity: 0.4 } : {}}
        onClick={() => {
          if (sel > 0) {
            let val = [];
            let tex = [];
            btn.map((item) => {
              if (item.selected) {
                val.push(item.value);
                tex.push(item.text);
              }
            });
            let data = { value: val.join() };
            if (nextQ) {
              data['next_q'] = nextQ;
            }
            props.sendResponse(data, tex.join(', '), current);
          }
        }}
      >
        <img src={require('../../../assets/send.svg').default} />
      </Submit>
    </div>
  );
}
export default MultiSelect;
