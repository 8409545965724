import React from 'react';
import { connect } from 'react-redux';
import {setOverlay} from '../../data/actions/appActions'

const FullscreenBtn = (props) => {
  return (
    <div className="btnfsc" onClick={()=>{
         props.setOverlay(<img className="picture" src={props.src} />)
    }}>
    <i class="fa fa-expand"></i>
    </div>
    )
}


const mapStateToProps = (state, ownProps) => {
  return {
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setOverlay:(child) => {
      dispatch(setOverlay(child))
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullscreenBtn);