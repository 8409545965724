import {
  setAppReady,
  setSurvey,
  setLang,
  getNext,
  setUserId,
} from './appActions';
import { map } from 'lodash';
import { getSurvey, exists, saveUser } from '../api.js';

import qs from 'qs';

export const appStart = (history, location, cookies) => {
  let tries = 0;

  const fetchSurvey = (sID, lang, userID) => {
    return async (dispatch, getCurrentState) => {
      if (sID && sID !== null && sID !== undefined) {
      }
      getSurvey(sID)
        .then((resp) => {
          let data = resp.data.data;

          dispatch(setSurvey(data));
          if (lang) {
            dispatch(setLang(data.lang, lang));
          } else {
            dispatch(setLang(data.lang, data.lang));
          }

          if (data.css) {
            let useCss = data.css.replace('.css', '');
            // const mainStyle = document.getElementById('style-main');
            // const modulesStyle = document.getElementById('style-modules');
            // const chatStyle = document.getElementById('style-chat');
            var head = document.head;
            exists(`/styles/${useCss}/main.css`).then((resp) => {
              if (resp.status) {
                var link = document.createElement('link');
                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = resp.url;

                head.appendChild(link);
              }
            });
            exists(`/styles/${useCss}/modules.css`).then((resp) => {
              if (resp.status) {
                var link = document.createElement('link');
                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = resp.url;

                head.appendChild(link);
              }
            });
            exists(`/styles/${useCss}/chat.css`).then((resp) => {
              if (resp.status) {
                var link = document.createElement('link');
                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = resp.url;

                head.appendChild(link);
              }
            });
          }
          return dispatch(getNext(-1, true));
        })
        .then(() => {
          return dispatch(setAppReady(true));
        })
        .catch((err) => {
          //console.log('err', err);
          if (tries < 10) {
            tries++;
            dispatch(fetchSurvey('info', lang, userID));
          } else {
            history.push('/404');
          }
          //if (err.status === 404) {
          //history.push('/search');
          //}
        });
    };
  };

  return async (dispatch, getCurrentState) => {
    let p = location.pathname;
    p = p.substr(1, p.length - 1);
    let goInfo = false;
    if (p === '') {
      p = 'info';
    } else if (p === '404') {
      return null;
    }

    let qstr = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    let userID = getCurrentState().app.userid;
    if (qstr.id) {
      dispatch(setUserId(qstr.id));
      userID = qstr.id;
    }
    // if (p !== '404' && p !== 'info') {
    //   let allC = cookies.getAll();
    //   let cookiename = 'elli_' + p;
    //   if (allC) {
    //     map(allC, (value, key) => {
    //       if (key === cookiename) {
    //         history.push('/thanks');
    //       }
    //     });
    //   }
    // }
    dispatch(fetchSurvey(p, qstr.lang, userID));
  };
};
