import React, { useState, useEffect, useRef } from 'react';
import angleup from '../../../assets/angleUp.png';
import Input from '../../global/Input';
import Submit from '../../global/Submit';
import EventEmitter from 'reactjs-eventemitter';

function InputType(props) {
  const [msg, setMsg] = useState('');

  const current = props.current;
  const tim = useRef(false);

  useEffect(() => {
    return () => {
      if (tim) {
        clearTimeout(tim.current);
      }
    };
  }, []);

  const sendResponse = () => {
    props.setThinking(false);
    let mess = msg.length > 0 ? msg : ' ';
    props.sendResponse(mess, mess, current);
    EventEmitter.dispatch('stopVideos');
  };

  return (
    <div className="responseHolder">
      <Input
        key="input"
        autoComplete="off"
        style={{
          marginRight: '10px',
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && msg.length > 0) {
            sendResponse();
          }
        }}
        placeholder={current.placeholder ? current.placeholder : ''}
        onChange={(e) => {
          setMsg(e.target.value);
          if (!props.thinking) {
            props.setThinking('user');
          }
          if (tim.current) {
            clearTimeout(tim.current);
          }
          tim.current = setTimeout(() => {
            props.setThinking(false);
          }, 2000);
        }}
        value={msg}
      />
      <Submit
        className="square"
        //style={msg.length === 0 ? { opacity: 0.4 } : {}}
        onClick={() => {
          // if (msg.length > 0) {
          sendResponse();
          //}
        }}
      >
        <img src={require('../../../assets/send.svg').default} />
      </Submit>
    </div>
  );
}
export default InputType;
