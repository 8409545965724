import React, { useEffect, useState, useRef } from 'react';
import angleup from '../../../assets/angleUp.png';
import { shuffle } from 'lodash';
import Input from '../../global/Input';
import Submit from '../../global/Submit';
import Button from '../../global/Button';

function ButtonNInput(props) {
  const current = props.current;
  let btns = current.action.options;
  const [btn, setBtn] = useState([]);
  const [msg, setMsg] = useState('');
  const [input, setInput] = useState(false);
  const tim = useRef(false);

  useEffect(() => {
    let btnHolder = [];
    if (!btns) {
      btns = current.action;
    }

    for (const key in btns) {
      if (key.indexOf('btn') > -1) {
        btnHolder.push({
          text: btns[key].text,
          value: btns[key].value,
          next_q: btns[key].next_q,
        });
      } else if (key === 'input') {
        setInput(btns[key]);
      }
    }
    if (current.action) {
      if (current.action.shuffle && current.action.shuffle === 1) {
        btnHolder = shuffle(btnHolder);
      }
    }
    setBtn(btnHolder);
    return () => {
      if (tim) {
        clearTimeout(tim.current);
      }
    };
  }, []);

  const selected = (btn) => {
    props.sendResponse(btn, btn.text, current);
  };

  return (
    <>
      <div className="buttonContainer">
        {btn.map((b, ind) => {
          return (
            <Button
              key={`btn_${ind}`}
              className="option noselect"
              onClick={() => {
                selected(b);
              }}
            >
              <p className="text">{b.text}</p>
              {b.subtext && <p className="subtext">{b.subtext}</p>}
            </Button>
          );
        })}
      </div>
      <div id="ui-response">
        <Input
          key="input"
          autoComplete="off"
          style={{
            marginRight: '10px',
          }}
          placeholder={current.placeholder ? current.placeholder : ''}
          onChange={(e) => {
            setMsg(e.target.value);
            if (!props.thinking) {
              props.setThinking('user');
            }
            if (tim.current) {
              clearTimeout(tim.current);
            }
            tim.current = setTimeout(() => {
              props.setThinking(false);
            }, 1500);
          }}
          value={msg}
        />
        <Submit
          className="square"
          style={msg.length === 0 ? { opacity: 0.4 } : {}}
          onClick={() => {
            if (msg.length > 0) {
              props.setThinking(false);
              props.sendResponse(
                { value: msg, next_q: input.next_q },
                msg,
                current
              );
            }
          }}
        >
          <img src={require('../../../assets/send.svg').default} />
        </Submit>
      </div>
    </>
  );
}
export default ButtonNInput;
