import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import EventEmitter from 'reactjs-eventemitter';
import { isMobile } from 'react-device-detect';

function Tube(props) {
  const { id, startS = 0, hidden, autoplay } = props;
  const [playing, setPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const vid = useRef(null);
  useEffect(() => {
    EventEmitter.subscribe('stopVideos', (event) => {
      if (hidden) {
        setShowVideo(false);
      }
    });
  }, []);

  if (!id || !showVideo) {
    return null;
  }
  let cla = 'videoholder inline';
  if (isMobile) {
    cla += ' mobile';
  }
  if (hidden && hidden === 'true') {
    cla += ' hidden';
  }
  return (
    <div className={cla}>
      <YouTube
        ref={vid}
        videoId={id}
        onReady={(e) => {
          if (autoplay && autoplay === 'true') {
            e.target.playVideo();
          }
        }}
        onPlay={() => {
          if (!playing) {
            setPlaying(true);
          }
        }}
        containerClassName={cla}
        opts={{
          height: '100%',
          width: '100%',
          playerVars: {
            autoplay: autoplay && autoplay === 'true' ? 1 : 0,
            start: parseInt(startS, 10),
            //origin: window.location.host,
          },
          host: 'https://www.youtube-nocookie.com',
        }}
      />
      {isMobile && hidden && hidden === 'true' && (
        <div
          className="videoOverlay"
          onClick={() => {
            if (vid.current) {
              if (!playing) {
                vid.current.internalPlayer.playVideo();
              } else {
                setPlaying(false);
                console.log(vid.current.internalPlayer);
                vid.current.internalPlayer.stopVideo();
              }
              // vid.current.target.playVideo();
            }
          }}
        >
          <div className={!playing ? 'playpause' : 'playpause pause'}></div>
        </div>
      )}
    </div>
  );
}
export default Tube;
