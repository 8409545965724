import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Chat from '../../containers/chat/chat';
import Controller from '../../containers/controller/controller';
import '../../styles/StartView.scss';

const StartView = (props) => {
  const { cookies } = props;
  return (
    <div className="startView">
      <div className="chatholder">
        <Chat cookies={cookies} />
        <Controller />
      </div>
    </div>
  );
};
export default withRouter(StartView);
