import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TweenLite, Elastic } from 'gsap/all';
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { isMobile } from 'react-device-detect';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Overlay from '../components/global/Overlay';
import Start from '../containers/start/start.js';
import { appStart } from '../data/actions/startActions';
import NotFound from '../components/404/NotFound.js';
import Thanks from '../components/thanks/Thanks.js';
import BigLoader from '../components/loaders/BigLoader.js';
import Intro from '../containers/intro/intro.js';
import logo from '../assets/logo.svg';
import loader from '../assets/bg2.json';
import deskloop from '../assets/deskloop.json';
import '../styles/main.scss';
import qs from 'qs';

gsap.registerPlugin(CSSPlugin);

function Container(props) {
  let qstr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const useMobile = qstr.isMobile || isMobile;
  if (qstr.isMobile) {
    document.body.classList.add('external');
  }
  const [looping, setLooping] = useState(false);
  const [currentL, _setL] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const [is404, set404] = useState(0);

  const [scaleing, setScale] = useState('xMidYMid slice');
  const [useAnim, setAnim] = useState(loader);

  const cL = useRef(currentL);
  const logoEl = useRef(null);
  //let bgRef = useRef();
  const { history, location, appStart, cookies } = props;

  useEffect(() => {
    if (
      location.pathname.indexOf('404') === -1 &&
      location.pathname.indexOf('thanks') === -1
    ) {
      appStart(history, location, cookies);
    } else {
      set404(true);
      setTimeout(() => {
        playOutro();
        goExtra();
      });
    }
  }, [history, location, appStart]);

  const setL = (data) => {
    cL.current = data;
    _setL(data);
  };

  useEffect(() => {
    if (props.appReady && logoEl.current !== null) {
      TweenLite.to(logoEl.current, 1, {
        scale: useMobile ? 0.4 : 0.5,
        y: 0 - window.innerHeight / 3,
        ease: Elastic.easeOut.config(1, 0.8),
      });
    }
  }, [props.appReady]);

  useEffect(() => {
    if (logoEl.current !== null) {
      if (props.appReady) {
        TweenLite.to(logoEl.current, 1, {
          scale: useMobile ? 0.4 : 0.5,
          y: 0 - window.innerHeight / 3,
          ease: Elastic.easeOut.config(1, 0.8),
        });
      }
    }
  }, [logoEl.current]);

  const imDone = (L) => {
    if (L <= 1) {
      setL(1);
      setLooping(true);
    }
    if (!useMobile && L === 2) {
      setL(3);
      setLooping(true);
      setScale('none');
      setAnim(deskloop);
    }
  };

  useEffect(() => {
    if (props.end) {
      setTimeout(() => {
        TweenLite.to(logoEl.current, 1, {
          scale: useMobile ? 0.4 : 0.5,
          opacity: 1,
          y: 0 - window.innerHeight / 3,
          ease: Elastic.easeOut.config(1, 0.8),
        });
      }, 2000);
    }
  }, [props.end]);

  useEffect(() => {
    if (useAnim === deskloop) {
      //bgRef.current.anim.play();
    }
  }, [useAnim]);

  const playOutro = () => {
    setOpacity(1);
    //bgRef.current.anim.setSpeed(1);
    setLooping(false);
    setL(2);
    // bgRef.current.anim.setSpeed(1);
    // bgRef.current.anim.playSegments([150, 200], true);
  };

  const videoEnded = () => {
    setOpacity(1);
    // bgRef.current.anim.setSpeed(1);
  };
  const fadeOut = () => {
    setOpacity(0.5);
    //bgRef.current.anim.pause();
    //bgRef.current.anim.setSpeed(0.2);
  };

  const goExtra = () => {
    TweenLite.to(logoEl.current, 1, {
      scale: useMobile ? 0.3 : 0.3,
      y: useMobile
        ? -window.innerHeight / 2 + 50
        : -window.innerHeight / 2 + 50,
      ease: Elastic.easeOut.config(1, 0.8),
    });
  };

  useEffect(() => {
    if (props.seenIntro && logoEl) {
      TweenLite.to(logoEl.current, 1, {
        opacity: 0,
      });
    }
  }, [props.seenIntro]);

  if (!props.appReady && !is404) {
    return (
      <React.Fragment>
        <div className="bganim logo">
          <img
            src={logo}
            className="logoimg"
            alt="logo"
            style={{ position: 'absolute', zIndex: 100 }}
          />
        </div>
        <BigLoader />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <div className={useMobile ? 'contentHolder' : 'contentHolder desk'}>
        <Router>
          <Switch>
            <Route path="/thanks" exact>
              <Thanks />
            </Route>
            <Route path="/404" exact>
              <NotFound />
            </Route>
            <Route path="/">
              {props.seenIntro && <Start cookies={cookies} />}
              {!props.seenIntro && (
                <Intro
                  outro={playOutro}
                  goExtra={goExtra}
                  fadeOut={fadeOut}
                  videoEnded={videoEnded}
                />
              )}
            </Route>
          </Switch>
        </Router>
      </div>
      <div className="bganim logo">
        <img
          src={logo}
          key={'logoimg'}
          className="logoimg"
          alt="logo"
          ref={logoEl}
          style={{ position: 'absolute', zIndex: 100 }}
        />
      </div>
      <Overlay />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    seenIntro: state.app.seenIntro,
    appReady: state.app.appReady,
    end: state.app.endQuestion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appStart: (h, l, c) => {
      return dispatch(appStart(h, l, c));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Container)
);
