import React, { useRef, useEffect } from 'react';
import BigLoader from '../loaders/BigLoader.js';

import EndText from '../global/EndText';
//import Submit from '../global/Submit';
//import Input from '../global/Input';
const NotFound = (props) => {
  //const [msg, setMSG] = useState('');
  const input = useRef();
  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  }, [input]);

  return (
    <div className="bganim logo" style={{ overflow: 'hidden' }}>
      <EndText>
        <h1>Oj!</h1>{' '}
        <h4>
          Något gick fel, <br />
          Tyvärr hittar vi inte det du söker.
        </h4>
      </EndText>
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          width: '90%',
          maxWidth: '400px',
          height: '48px',
          top: '55%',
          bottom: '50%',
          alignItems: 'center',
          zIndex: 99,
        }}
      >
        {/*   <Input
          key="input"
          ref={input}
          autoComplete="off"
          style={{
            marginRight: '10px',
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              window.location = '/' + msg;
            }
          }}
          placeholder={''}
          onChange={(e) => {
            setMSG(e.target.value.toUpperCase());
          }}
          value={msg}
        />
        <Submit
          className="square"
          onClick={() => {
            if (msg.length > 0) {
              window.location = '/' + msg;
            }
          }}
        >
          <img src={require('../../assets/send.svg').default} />
        </Submit>*/}
      </div>
      <BigLoader />
    </div>
  );
};
export default NotFound;
