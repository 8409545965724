import { connect } from 'react-redux';
import { handleResponse, setThinking } from '../../data/actions/appActions';
import { spellCheck } from '../../data/api';
import ControllView from '../../components/controller/ControllView';

const mapStateToProps = (state, ownProps) => {
  let { currentQuestion, questions } = state.app;

  return {
    current: questions[currentQuestion],
    thinking: state.app.thinking,
    end: state.app.endQuestion,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendResponse: (data, msg, current) => {
      return dispatch(handleResponse(data, msg, current));
    },
    setThinking: (data) => {
      return dispatch(setThinking(data));
    },
    checkSpelling: (data) => {
      return spellCheck(data);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControllView);
