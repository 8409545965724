import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Vimeo from '@u-wave/react-vimeo';
import YouTube from 'react-youtube';
import qs from 'qs';
import Button from '../global/Button';
import bbCode from '../global/bbCode';
import '../../styles/IntroView.scss';
import '../../styles/global/Video.scss';

function IntroView(props) {
  const [showCustom, setShow] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [videotype, setVideoType] = useState('vimeo');
  const [videoId, setVideoId] = useState(false);
  const [startS, setStartS] = useState(0);

  const tim = useRef();
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 1,
      iv_load_policy: 3,
      modestbranding: 1,
      enablejsapi: 1,
      playsinline: 0,
      showinfo: 0,
      disablekb: 1,
      rel: 0,
    },
  };
  const youtube_parser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };
  const checkBBCodes = (msg) => {
    return bbCode.toReact(msg);
  };
  useEffect(() => {
    if (
      props?.custom?.action?.video_id &&
      props?.custom?.action?.video_id.indexOf('youtube') > -1
    ) {
      setVideoType('youtube');
      let vid = youtube_parser(props.custom.action.video_id);
      setVideoId(vid);
      let qstr = qs.parse(
        props.custom.action.video_id.slice(
          props.custom.action.video_id.lastIndexOf('?') + 1
        ),
        {
          ignoreQueryPrefix: true,
        }
      );

      if (qstr.start) {
        setStartS(qstr.start);
      }
    } else {
      if (props?.custom?.action?.video_id) {
        setVideoId(props.custom.action.video_id);
      }
    }
    if (props.survey.intro_button_delay > 0) {
      setWaiting(true);
      tim.current = setTimeout(() => {
        setWaiting(false);
      }, props.survey.intro_button_delay);
    }
    return () => {
      if (tim.current) {
        clearTimeout(tim.current);
      }
    };
  }, []);

  useEffect(() => {
    if (props.isReady && !waiting) {
      setWaiting(false);
    }
  }, [props.isReady]);

  const ended = () => {
    props.videoEnded();
    setShowBtn(true);
  };

  useEffect(() => {
    if (props.custom) {
      if (props.custom.action.force_watch === 0) {
        setShowBtn(true);
      }
    }
  }, [props.custom]);
  /*
  
  <YouTube
              containerClassName={'videoholder'}
              videoId="2g811Eo7K8U"
              opts={opts}
            />
   */
  return (
    <div className={!showCustom ? 'introHolder start' : 'introHolder'}>
      {!showCustom && (
        <div className="textBlock">{checkBBCodes(props.survey.intro_text)}</div>
      )}
      {!showCustom && (
        <div className="textBlock">
          <Button
            className="noselect"
            style={!props.isReady || waiting ? { opacity: '0.5' } : {}}
            onClick={() => {
              if (props.isReady && !waiting) {
                if (!props.custom) {
                  props.outro();
                  props.setSeen();

                  let q = props.questions[props.currQ];
                  if (q.type === 'info') {
                    let qdata = q.action;
                    if (!qdata.action && qdata.next_q) {
                      let del = 10;
                      if (qdata.delay) {
                        del = qdata.delay;
                      }
                      setTimeout(() => {
                        props.getNext();
                      }, del);
                    }
                  }
                } else {
                  setShow(true);
                  props.goExtra();
                  props.getNext(props.custom.action.next_q);

                  // console.log('REMEMBER REmove next two lines');
                  // props.outro();
                  // props.setSeen();
                }
              }
            }}
          >
            {props.survey.intro_button}
            <img
              style={{ marginLeft: 20 }}
              src={require('../../assets/send.svg').default}
            />
          </Button>
        </div>
      )}
      {props.isReady && showCustom && (
        <>
          {props.custom.type === 'video_intro' && videotype === 'vimeo' && (
            <Vimeo
              video={videoId}
              width={'100%'}
              height={'100%'}
              autoplay={false}
              className="videoholder"
              showByline={false}
              controls={true}
              onEnd={ended}
              onPlay={() => {
                props.fadeOut();
              }}
              webkitAllowFullScreen={true}
              mozallowfullscreen={true}
              allowFullScreen={true}
            />
          )}
          {props.custom.type === 'video_intro' && videotype === 'youtube' && (
            <YouTube
              videoId={videoId}
              containerClassName={'videoholder'}
              opts={{
                height: '100%',
                width: '100%',
                playerVars: {
                  autoplay: 0,
                  start: startS,
                  origin: window.location.host,
                },
                host: 'https://www.youtube-nocookie.com',
              }}
              onEnd={ended}
              onPlay={() => {}}
            />
          )}
          {props.custom.question.length > 0 && (
            <div style={{ marginBottom: '20px' }}>
              {checkBBCodes(props.custom.question)}
            </div>
          )}
          <div
            className="btn noselect"
            style={showBtn ? { opacity: 1 } : { opacity: 0.2 }}
            onClick={() => {
              if (showBtn) {
                props.outro();
                props.setSeen();
              }
            }}
          >
            {props.custom.action.btn}
          </div>
        </>
      )}
    </div>
  );
}
export default withRouter(IntroView);
