import { ADD } from '../actions/threadActions';

const initialState = {
  thread: [],
};
const threadReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD: {
      let thread = [...state.thread];
      let data = action.data;
      data['ts'] = new Date().getTime();
      if (data.question) {
        data['msg'] = data.question;
      }
      thread.push(data);
      return { ...state, thread };
    }
    default: {
      return state;
    }
  }
};

export default threadReducer;
