import React, { Component } from 'react';
import Container from './containers/Container';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './data/reducers';
import { withCookies, Cookies } from 'react-cookie';

const store = createStore(rootReducer, applyMiddleware(thunk));
const history = require('history').createMemoryHistory();

const App = (props) => {
  const { cookies } = props;
  return (
    <Provider store={store} history={history}>
      <Router>
        <Container cookies={cookies} />
      </Router>
    </Provider>
  );
};

export default withCookies(App);
