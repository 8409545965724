import {
  getNextQuestion,
  submitResponse,
  submitResponseQuiz,
  submitAnalyze,
  submitGoogle,
  submitCint,
  submitQuiz,
  saveUser,
  submitStatus,
} from '../api.js';
import { cloneDeep } from 'lodash';
import CONFIG from '../../config/config.js';
import { addToThread } from './threadActions';
export const SET_CURRENT = 'setCurrent';
export const ADD_ANSWER = 'addAnswer';
export const ADD_QUESTION = 'addQuestion';
export const SET_SURVEY = 'setSurvey';
export const SET_LANG = 'setLang';
export const SET_APP_READY = 'setAppReady';
export const UPDATE_QUESTION_TRIES = 'updateTries';
export const SET_THINKING = 'setThinking';
export const SET_SEEN_INTRO = 'setSeenIntro';
export const ADD_CUSTOM_INTRO = 'addCustomIntro';
export const ADD_END_QUESTION = 'addEndQuestion';
export const SET_USERID = 'setUserId';
export const SET_UID_SAVED = 'setUidSaved';
export const SET_OVERLAY = 'setOverlay';

export const setOverlay = (data) => {
  return {
    type: SET_OVERLAY,
    data,
  };
};
export const setSurvey = (survey) => {
  return {
    type: SET_SURVEY,
    data: survey,
  };
};

export const addCustomIntro = (intro) => {
  return {
    type: ADD_CUSTOM_INTRO,
    data: intro,
  };
};
export const addEndQuestion = (end) => {
  return {
    type: ADD_END_QUESTION,
    data: end,
  };
};

export const setUserId = (data) => {
  return {
    type: SET_USERID,
    data,
  };
};

export const setCurrent = (id) => {
  return {
    type: SET_CURRENT,
    id,
  };
};
export const setSeenIntro = (data) => {
  return {
    type: SET_SEEN_INTRO,
    data,
  };
};
export const setThinking = (data) => {
  return {
    type: SET_THINKING,
    data,
  };
};

export const updateTries = (id) => {
  return {
    type: UPDATE_QUESTION_TRIES,
    id,
  };
};

export const setLang = (fromLang, toLang) => {
  return {
    type: SET_LANG,
    data: { fromLang, toLang },
  };
};

export const addQuestion = (data) => {
  return {
    type: ADD_QUESTION,
    data,
  };
};
export const addAnswer = (data) => {
  return {
    type: ADD_ANSWER,
    data,
  };
};

export const setAppReady = (ready) => {
  return {
    type: SET_APP_READY,
    appReady: ready,
  };
};

export const setUidSaved = () => {
  return {
    type: SET_UID_SAVED,
    uidSaved: true,
  };
};

export const getNext = (nextQ, start = false) => {
  return async (dispatch, getCurrentState) => {
    let {
      currentQuestion,
      survey,
      fromLang,
      toLang,
      questions,
    } = getCurrentState().app;
    let qNr = survey.start_id;
    if (currentQuestion) {
      let next = questions[currentQuestion].action;
      qNr = next.next_q;
    }
    if (nextQ && !start) {
      qNr = nextQ;
    }
    dispatch(setThinking('robot'));

    return getNextQuestion(survey.name, qNr, toLang, fromLang)
      .then((data) => {
        if (data.status === 200) {
          let resp = data.data.data;

          if (resp.length > 0) {
            resp = resp[0];
            if (resp.action && resp.action.length > 0) {
              resp.action = JSON.parse(resp.action);
            }
            return resp;
          }
        }
        return false;
      })
      .then((response) => {
        if (response) {
          dispatch(setThinking(false));
          // console.log('resp', response);
          if (response.type !== 'video_intro') {
            if (!start) {
              let foundEnd = false;
              let qdata = response.action;
              let next_q = qdata?.next_q ? qdata.next_q.toString() : '';
              if (qdata && !qdata?.action && next_q) {
                if (next_q.indexOf('end') === -1 && !qdata.cint) {
                  let del = 10;
                  if (qdata.delay) {
                    del = qdata.delay;
                  }
                  setTimeout(() => {
                    return dispatch(getNext());
                  }, del);
                } else {
                  foundEnd = true;
                }
              } else if ((next_q && next_q.indexOf('end') > -1) || qdata.cint) {
                foundEnd = true;
              }
              if (response?.action && response.action?.status) {
                submitStatus({
                  guid: getCurrentState().app.userid,
                  status: response.action?.status,
                })
                  .then((res) => {
                    //  console.log('savestatus', res);
                  })
                  .catch((err) => {
                    //  console.log(err)
                  });
              }

              if (!foundEnd) {
                dispatch(setCurrent(response.id));
                dispatch(addQuestion(response));
                dispatch(addToThread(response));
              } else {
                let del = 10;
                if (qdata.delay) {
                  del = qdata.delay;
                }

                setTimeout(() => {
                  dispatch(setCurrent(null));

                  dispatch(addEndQuestion(response));
                  if (qdata?.cint && !qdata?.quiz) {
                    let sid = getCurrentState().app.survey.cint_id;
                    if (qdata.token) {
                      sid = qdata.token;
                    }
                    handleCintEnd(
                      getCurrentState().app.userid,
                      qdata.cint,
                      sid,
                      response
                    );
                  } else if (qdata?.url) {
                    var link = document.createElement('a');
                    link.href = qdata.url;
                    link.target = qdata?.target ? qdata.target : '_self';
                    document.body.appendChild(link);
                    link.click();
                  }
                }, del);
              }
            } else if (start) {
              dispatch(setCurrent(response.id));
              dispatch(addQuestion(response));
              dispatch(addToThread(response));
            }
          } else {
            return dispatch(addCustomIntro(response));
          }
        }
      });
  };
};

export const handleResponse = (data, tex, current) => {
  return async (dispatch, getCurrentState) => {
    let uid = getCurrentState().app.userid;
    let sid = getCurrentState().app.survey.name;
    let del = 0;

    if (current.type !== 'text') {
      dispatch(setThinking('user'));
      setTimeout(() => {
        dispatch(setThinking(false));
        dispatch(addToThread({ msg: tex, id: new Date().getTime() }));
      }, 200);
    } else {
      dispatch(addToThread({ msg: tex, id: new Date().getTime() }));
    }
    dispatch(addAnswer({ msg: tex, id: new Date().getTime() }));

    dispatch(setCurrent(null));
    if (!getCurrentState().app?.uidSaved) {
      await saveUser(uid, getCurrentState().app.survey.name)
        .then((r) => {
          dispatch(setUidSaved(true));
        })
        .catch((err) => {
          //console.log("err",err)
        });
    }
    if (current.type === 'text') {
      if (current.action && current.action.action) {
        let a = current.action.action;
        //console.log('action', current.action);
        switch (a) {
          case 'analyze': {
            dispatch(setThinking('robot'));
            return submitAnalyze(
              current.action.next_q,
              current.action.intent,
              data,
              current.tries + 1
            ).then((res) => {
              let response = res.data.data;
              //console.log('analyze data', response);
              if (
                response?.intent &&
                response?.intent !== response.intent_wanted
              ) {
                let q = cloneDeep(current);
                q.question = response.copy;
                q.id = current.id + '_' + current.tries;
                dispatch(updateTries(current.id));
                dispatch(setThinking(false));
                dispatch(addToThread(q));
                dispatch(setCurrent(current.id));
              } else if (
                response?.intent &&
                response.intent === response.intent_wanted
              ) {
                internalFunctions(response.intent_wanted);
                if (response?.action?.delay) {
                  del = response.action.delay;
                  if (response.copy) {
                    let q = cloneDeep(current);
                    q.question = response.copy;
                    q.id = current.id + '_' + current.tries;
                    dispatch(setThinking(false));
                    dispatch(addToThread(q));
                  }
                }

                return setTimeout(() => {
                  return submitResponse(current.id, data, uid, sid).then(
                    (resp) => {
                      del = 10;
                      if (current.action) {
                        if (current.action.delay) {
                          del = current.action.delay;
                        }
                      }
                      setTimeout(() => {
                        dispatch(getNext(current.action.next_q));
                      }, del);
                    }
                  );
                }, del);
              }
            });
          }
          case 'quiz': {
            dispatch(setThinking('robot'));
            return submitQuiz(
              current.action.next_q,
              data,
              current.tries + 1
            ).then((res) => {
              let response = res.data.data;
              // console.log('quiz data', res);

              let q = cloneDeep(current);
              q.question = response.copy;
              q.id = current.id + '_' + current.tries;
              dispatch(updateTries(current.id));
              dispatch(setThinking(false));
              dispatch(addToThread(q));
              dispatch(setCurrent(current.id));

              if (response?.action?.delay) {
                del = response.action.delay;
                if (response.copy) {
                  let q = cloneDeep(current);
                  q.question = response.copy;
                  q.id = current.id + '_' + current.tries;
                  dispatch(setThinking(false));
                  dispatch(addToThread(q));
                }
              }

              return setTimeout(() => {
                return submitResponse(current.id, data, uid, sid).then(
                  (resp) => {
                    del = 10;
                    if (current.action) {
                      if (current.action.delay) {
                        del = current.action.delay;
                      }
                    }
                    setTimeout(() => {
                      dispatch(getNext(current.action.next_q));
                    }, del);
                  }
                );
              }, del);
            });
          }
          case 'google': {
            dispatch(setThinking('robot'));
            return submitGoogle(
              current.action.next_q,
              current.action.intent,
              data
            ).then((res) => {
              let response = res.data.data;
              if (response) {
                let q = cloneDeep(current);
                q.question = response;
                q.id = current.id + '_' + current.tries;
                dispatch(updateTries(current.id));
                //dispatch(addQuestion(q));
                dispatch(setThinking(false));
                dispatch(addToThread(q));
                return submitResponse(current.id, data, uid, sid).then(
                  (resp) => {
                    del = 10;
                    if (current.action) {
                      if (current.action.delay) {
                        del = current.action.delay;
                      }
                    }
                    setTimeout(() => {
                      dispatch(getNext(current.action.next_q));
                    }, del);
                  }
                );
              }
            });
          }
          case 'save': {
            dispatch(setThinking('robot'));
            return submitResponse(current.id, data, uid, sid).then((resp) => {
              //   console.log('save', resp, current);
              if (resp.status === 200) {
                del = 10;
                if (current.action) {
                  if (current.action.delay) {
                    del = current.action.delay;
                  }
                }

                setTimeout(() => {
                  dispatch(setThinking(false));

                  dispatch(getNext(current.action.next_q));
                }, del);
              }
            });
          }
          default: {
            return false;
          }
        }
      }
    } else if (
      current.type === 'multi' ||
      current.type === 'buttons' ||
      current.type === 'buttons_free' ||
      current.type === 'dropdown' ||
      current.type === 'slide' ||
      current.type === 'quiz'
    ) {
      return submitResponse(
        current.id,
        data.value,
        getCurrentState().app.userid,
        sid
      ).then((resp) => {
        if (resp.status === 200) {
          del = 300;
          if (current.action) {
            if (current.action.delay) {
              del = current.action.delay;
            }
          }
          setTimeout(() => {
            dispatch(getNext(data.next_q));
          }, del);
        }
      });
    }

    return null;
  };
};

export const handleCintEnd = (uid, cint_id, survey_cint, current) => {
  return submitCint(uid, cint_id)
    .then((res) => {
      if (res.status === 200) {
        let del = 4000;
        if (current?.action && current?.action?.delay) {
          del = current.action.delay;
        }
        if (survey_cint) {
          setTimeout(() => {
            if (cint_id === 5) {
              window.location.href = CONFIG.cintHost5 + survey_cint;
            } else {
              window.location.href = CONFIG.cintHost2 + survey_cint;
            }
          }, del);
        }
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const internalFunctions = (fn) => {
  switch (fn) {
    case 'select_name': {
      console.log('found intent', fn);
      break;
    }
    case 'select_color': {
      console.log('found intent', fn);
      break;
    }
    case 'select_ad': {
      console.log('found intent', fn);
      break;
    }
    default: {
      console.log('intent not found ', fn);
      break;
    }
  }
};
