import React from 'react';
import { withRouter } from 'react-router-dom';
import Lottie from 'react-lottie';
import loader from '../../assets/bigloader.json';
import '../../styles/loader.scss';

function BigLoader(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <div className="bigLoader"></div>;
}
export default withRouter(BigLoader);
