import React from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import qs from 'qs';
import { setOverlay } from '../../data/actions/appActions';
import '../../styles/global/Overlay.scss';

const Overlay = (props) => {
  let qstr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const useMobile = qstr.isMobile || isMobile;

  if (!props.overlay) {
    return null;
  }
  return (
    <div
      className={useMobile ? 'overlay mobile' : 'overlay'}
      onClick={() => {
        props.hideOverlay();
      }}
    >
      {props.overlay}
      <div
        className="btnfsc"
        onClick={() => {
          props.hideOverlay();
        }}
      >
        {' '}
        <i className="fas fa-compress" />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    overlay: state.app.overlay,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    hideOverlay: () => {
      dispatch(setOverlay(false));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overlay);
