import React, { useEffect, useState } from 'react';
import { shuffle } from 'lodash';
import Button from '../../global/Button';

function ButtonsType(props) {
  const current = props.current;
  let btns = current.action.options;
  const [btn, setBtn] = useState([]);

  useEffect(() => {
    let btnHolder = [];
    if (!btns) {
      btns = current.action;
    }

    for (const key in btns) {
      btnHolder.push({
        text: btns[key].text,
        value: btns[key].value,
        next_q: btns[key].next_q,
      });
    }
    if (current.action) {
      if (current.action.shuffle && current.action.shuffle === 1) {
        btnHolder = shuffle(btnHolder);
      }
    }
    setBtn(btnHolder);
  }, []);

  const selected = (btn) => {
    props.sendResponse(btn, btn.text, current);
  };

  return (
    <div className="buttonContainer">
      {btn.map((b, ind) => {
        return (
          <Button
            key={`btn_${ind}`}
            className="option noselect"
            onClick={() => {
              selected(b);
            }}
          >
            <p className="text">{b.text}</p>
            {b.subtext && <p className="subtext">{b.subtext}</p>}
          </Button>
        );
      })}
    </div>
  );
}
export default ButtonsType;
