import React from 'react';
import { render } from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
serviceWorker.unregister();

//store.dispatch(appStart(history));

render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('container')
);
