import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import qs from 'qs';
import Button from '../../global/Button';
import '../../../styles/global/SlideModule.scss';
import Carousel from 'nuka-carousel';

function SlideModule(props) {
  let qstr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const useMobile = qstr.isMobile || isMobile;

  const current = props.current;
  let slide = current.action.slides;
  let btn = current.action?.btn ? current.action?.btn : 'Öppna';
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = window.innerWidth;
  let slides = [];
  for (const key in slide) {
    slides.push({
      src: slide[key].src,
      text: slide[key].text,
      value: slide[key].value,
      next_q: slide[key].next_q,
    });
  }
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    } else {
      setClickedOutside(false);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    if (clickedOutside) {
      setOpen(false);
    }
  }, [clickedOutside]);
  return (
    <div ref={myRef} className={!open ? 'slideModule' : 'slideModule open'}>
      {open && (
        <>
          <Carousel
            dragging={true}
            //withoutControls={true}

            cellAlign="center"
            renderTopCenterControls={({ currentSlide }) => {
              return <h4 className="title">{slides[currentSlide].text}</h4>;
            }}
            afterSlide={(slideIndex) => setCurrentIndex(slideIndex)}
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide }) => null}
            renderCenterRightControls={({ nextSlide }) => null}
          >
            {slides.map((pic, index) => {
              return (
                <div
                  key={'slide' + index}
                  onClick={(i) => {
                    props.sendResponse(pic, pic.text, current);
                  }}
                  className="picholder"
                  style={{
                    backgroundImage: `url(${pic.src})`,
                    width: useMobile ? '100%' : width,
                  }}
                ></div>
              );
            })}
          </Carousel>
          <div className="dottholder">
            {slides.map((pic, index) => {
              return (
                <div
                  key={'dott' + index}
                  className={currentIndex === index ? 'dott active' : 'dott'}
                ></div>
              );
            })}
          </div>
        </>
      )}
      {!open && (
        <Button
          className="noselect"
          //style={msg.length === 0 ? { opacity: 0.4 } : {}}
          onClick={() => {
            setOpen(true);
            // props.sendResponse(selected, selected.text, current);
          }}
        >
          {btn}
          <img
            style={{ marginLeft: 10 }}
            src={require('../../../assets/send.svg').default}
          />
        </Button>
      )}
    </div>
  );
}
export default SlideModule;
