import React from 'react';
import BigLoader from '../loaders/BigLoader.js';

export default function Thanks(props) {
  return (
    <div className="bganim logo" style={{ overflow: 'hidden' }}>
      <div className="endText">Tack för ditt deltagande!</div>
      <BigLoader />
    </div>
  );
}
