import React, { useEffect } from 'react';

import FullscreenBtn from './FullscreenBtn'
import { Parser, Tag } from 'bbcode-to-react';
import Tube from './Tube';
import Spotify from './Spotify';

class YTube extends Tag {
  toReact() {
    return (
      <>
        <Tube {...this.params} />
      </>
    );
  }
}
class SpotifyPlayer extends Tag {
  toReact() {
    return (
      <>
        <Spotify {...this.params} />
      </>
    );
  }
}
class h4 extends Tag {
  toReact() {
    return <h4>{this.getComponents()}</h4>;
  }
}
class h3 extends Tag {
  toReact() {
    return <h3>{this.getComponents()}</h3>;
  }
}
class h2 extends Tag {
  toReact() {
    return <h2>{this.getComponents()}</h2>;
  }
}
class h1 extends Tag {
  toReact() {
    return <h1>{this.getComponents()}</h1>;
  }
}
class b extends Tag {
  toReact() {
    return <b>{this.getComponents()}</b>;
  }
}
class br extends Tag {
  toReact() {
    return <br></br>;
  }
}
class p extends Tag {
  toReact() {
    return <p>{this.getComponents()}</p>;
  }
}
class i extends Tag {
  toReact() {
    return <i>{this.getComponents()}</i>;
  }
}
class a extends Tag {
  toReact() {
    return (
      <a
        href={this.params.href}
        target={this.params?.target ? this.params.target : ''}
      >
        {this.getContent()}
      </a>
    );
  }
}
class img extends Tag {
  toReact() {
    let style = {};
    let allowFullscreen = false
    if (this.params?.round) {
      style.borderRadius = this.params.round + 'px';
    }
    if (this.params?.border) {
      style.borderWidth = this.params.border + 'px';
      style.borderStyle = 'solid';
    }
    if (this.params?.bordercolor) {
      style.borderColor = this.params.bordercolor;
    }
    if(this.params?.fullscreen){
      allowFullscreen = true
    }
    return (
      <>
        {!allowFullscreen &&(<img className="picture" style={style} src={this.params.src} />)}
        {allowFullscreen && (
            <div className="picture">
              <img className="picture" style={style} src={this.params.src} />
              <FullscreenBtn src={this.params.src}/>
            </div>
          )}
      </>
    );
  }
}

const bbCode = new Parser([]);
bbCode.registerTag('youtube', YTube);
bbCode.registerTag('spotify', SpotifyPlayer);
bbCode.registerTag('h4', h4);
bbCode.registerTag('h3', h3);
bbCode.registerTag('h2', h2);
bbCode.registerTag('h1', h1);
bbCode.registerTag('b', b);
bbCode.registerTag('i', i);
bbCode.registerTag('br', br);
bbCode.registerTag('p', p);
bbCode.registerTag('a', a);
bbCode.registerTag('img', img);

export default bbCode;
