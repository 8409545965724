import React from 'react';

import '../../styles/global/Submit.scss';

const Submit = (props) => {
  let extra = {};
  if (props?.style) {
    extra = props.style;
  }
  return (
    <div
      style={extra}
      onClick={() => {
        if (props?.onClick) {
          props.onClick();
        }
      }}
      className={props.className ? 'submit ' + props.className : 'submit'}
    >
      {props.children}
    </div>
  );
};

export default Submit;
