import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import qs from 'qs';
// import SpotifyPlayer from 'react-spotify-web-playback';
// import { decode as atob, encode as btoa } from 'base-64';

// const client_id = '51fc46b56eca472daa9d26a2ccc008f3';
// const client_secret = 'ecdf2fda7c5c4a48af56d4307fb90d0f';

const Spotify = (props) => {
  if (!props.track) {
    return null;
  }
  return (
    <iframe
      src={`https://open.spotify.com/embed/track/${props.track}?utm_source=generator`}
      width="80"
      height="80"
      frameBorder="0"
      allowfullscreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>
    // <iframe
    //   src="https://open.spotify.com/track/68GdZAAowWDac3SkdNWOwo?si=320f97eadbb74f44"
    //   width="300"
    //   height="380"
    //   frameBorder="0"
    //   allowtransparency="true"
    //   allow="encrypted-media"
    // ></iframe>
    // <SpotifyPlayer
    //   token={token}
    //   uris={['spotify:artist:6HQYnRM4OzToCYPpVBInuU']}
    // />
  );
};
export default Spotify;
