import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

import Lottie from 'react-lottie';
import { TweenMax, Bounce, TimelineLite } from 'gsap/all';

import { gsap } from 'gsap';
import BigLoader from '../loaders/BigLoader.js';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import moment from 'moment-timezone';

import loader from '../../assets/miniloader2.json';

import '../../styles/ChatView.scss';
import '../../styles/global/Video.scss';
import bbCode from '../global/bbCode';

gsap.registerPlugin(MotionPathPlugin);

function ChatView(props) {
  const { cookies } = props;
  const [thread, setThread] = useState([]);
  const [scrolling, setScrolling] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const endBg = useRef(null);

  const tim = useRef();
  const t2 = useRef();
  const chatHolder = useRef();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    transform: 'scale(1, -1) translate(0, -100)',
  };
  const checkBBCodes = (msg) => {
    return bbCode.toReact(msg);
  };
  useEffect(() => {
    if (JSON.stringify(props.thread) !== JSON.stringify(thread)) {
      if (!props.end) {
        let a = [];
        props.thread.forEach((item) => {
          if (!item?.custom) {
            item.custom = Math.random() * 788;
          }
          a.push(item);
        });

        setThread(a);
        scrollToBottom();
      }
    }
  }, [props.thread]);
  const handleScroll = () => {
    setScrolling(true);
  };
  useEffect(() => {
    if (!scrolling) {
      if (t2.current) {
        clearInterval(t2.current);
      }
      tim.current = setInterval(() => {
        if (!scrolling && !props.end) {
          scrollToBottom();
        }
      }, 1000);
    } else {
      if (tim.current) {
        clearInterval(tim.current);
      }
      if (t2.current) {
        clearInterval(t2.current);
      }
      t2.current = setTimeout(() => {
        setScrolling(false);
      }, 1000);
    }
  }, [scrolling]);

  useEffect(() => {
    return () => {
      if (tim.current) {
        clearInterval(tim.current);
      }
    };
  }, []);

  useEffect(() => {
    if (chatHolder.current) {
      chatHolder.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (chatHolder.current) {
        chatHolder.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [chatHolder]);

  useEffect(() => {
    if (props.end) {
      if (props.survey.name !== 'info') {
        let c = props.cookies.get('elli_' + props.survey.name);

        if (!c || c === undefined) {
          document.cookie =
            'elli_' +
            props.survey.name +
            '=1' +
            ';expires=' +
            moment()
              .add(1, 'year')
              .toDate() +
            '; SameSite=None; Secure';

          window.parent.postMessage(
            {
              func: 'setC',
              message:
                'elli_' +
                props.survey.name +
                '=1' +
                ';path=/;expires=' +
                moment()
                  .add(1, 'year')
                  .toDate() +
                '; ',
            },
            '*'
          );
          props.cookies.set('elli_' + props.survey.name, 1, {
            domain: 'chatwithelli.com',
            expires: moment()
              .add(1, 'year')
              .toDate(),
          });
        }
      }
      setTimeout(() => {
        var texts = Array.prototype.slice.call(
          document.querySelectorAll('.response')
        );
        let tl = new TimelineLite({
          onComplete: () => {
            setIsRemoved(true);
            TweenMax.to(endBg.current, 1, { opacity: 1, delay: 2 });
          },
        });
        texts.forEach((item, index) => {
          tl.to(
            texts[index],
            1,
            {
              y: 200,
              opacity: 0,
              ease: Bounce.easeOut,
            },
            index > 0 ? '-=1' : ''
          );
        });
      }, 10);
    }
  }, [props.end]);

  const getElementsByClassName = (node, classname) => {
    if (node.getElementsByClassName) {
      return node.getElementsByClassName(classname);
    } else {
      return (function getElementsByClass(searchClass, node) {
        if (node == null) node = document;
        var classElements = [],
          els = node.getElementsByTagName('*'),
          elsLen = els.length,
          pattern = new RegExp('(^|\\s)' + searchClass + '(\\s|$)'),
          i,
          j;

        for (i = 0, j = 0; i < elsLen; i++) {
          if (pattern.test(els[i].className)) {
            classElements[j] = els[i];
            j++;
          }
        }
        return classElements;
      })(classname, node);
    }
  };
  const scrollToBottom = () => {
    let len = Object.keys(thread).length;
    if (len > 0 && chatHolder.current) {
      animateScroll.scrollTo(chatHolder.current.offsetHeight);
    }
  };

  var control = getElementsByClassName(document, 'inputholder');
  let useH = window.innerHeight;

  if (control) {
    if (control.offsetHeight > 72) {
      useH -= control.offsetHeight;
    } else {
      useH -= 72;
    }
  }
  return (
    <div className="chat" ref={chatHolder} style={{ height: useH }}>
      {props.thinking && props.thinking === 'robot' && !props.end && (
        <div
          key={'thinking_dotts'}
          className={['response robot ']}
          style={{ padding: '0px' }}
        >
          <Lottie options={defaultOptions} height={33} width={72} />
        </div>
      )}

      {!isRemoved &&
        Object.keys(thread).map((key, index) => {
          let item = thread[key];
          if (!item?.msg) {
            return null;
          }
          return (
            <div
              key={item.id + '_' + item.custom}
              id={'resp_' + item.custom}
              className={[!item.question ? 'response user' : 'response robot']}
            >
              {checkBBCodes(item.msg)}
              {/*{checkBBCodes(
                '[spotify track="68GdZAAowWDac3SkdNWOwo"][/spotify]'
              )}*/}

              {/*{!item.question &&
                checkBBCodes(
                  `
                    [h1]H1[/h1]
                    [h2]H2[/h2]
                    [h3]H3[/h3]
                    [h4]H4[/h4]
                   
                    [b]Bolded[/b][br][/br] 
                    [i]Italic[/i]
                    `
                )}
              {item.question &&
                checkBBCodes(
                  `
                    [img src="http://placekitten.com/600/700" round="30" border="5" bordercolor="green"][/img][p][/p]
                    [img src="http://placekitten.com/500/400"][/img][p][/p]
                    [a href="http://www.google.se" target="_blank"]a href text[/a][p][/p]
                    [youtube id="XaujwjUDD1s" autoplay="false" hidden="false"][/youtube]
                    `
                )}*/}
            </div>
          );
        })}

      {props.end && (
        <div
          className="bganim logo"
          ref={endBg}
          style={{ overflow: 'hidden', opacity: 0 }}
        >
          <div className="endText">{checkBBCodes(props.end.question)}</div>
          <BigLoader />
        </div>
      )}
    </div>
  );
}
export default withRouter(ChatView);
