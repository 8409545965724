import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import qs from 'qs';
import Button from '../../global/Button';
import Submit from '../../global/Submit';
import Input from '../../global/Input';
import '../../../styles/global/QuizModule.scss';
import Carousel from 'nuka-carousel';

function QuizModule(props) {
  let qstr = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const useMobile = qstr.isMobile || isMobile;

  const current = props.current;
  let btn = current.action?.btn ? current.action?.btn : 'Öppna';
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState([]);

  const slider = useRef(null);
  const [correct, setCorrect] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState(false);
  const { width } = window.innerWidth;

  const [msgArr, setMsgArr] = useState([]);
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  useEffect(() => {
    let allSlides = [];
    let intArr = [];
    let slide = current.action.quiz;
    slide.map((item) => {
      let s = {
        text: item.text,
        correct: item.correct,
      };
      if (item?.options) {
        s.options = item.options;
      }
      if (item?.pic) {
        s.pic = item.pic;
      }
      if (item?.input) {
        s.isInput = true;
        intArr.push('');
        s.inputInt = intArr.length - 1;
      }
      allSlides.push(s);
    });
    setMsgArr(intArr);
    setSlides(allSlides);
  }, []);

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(true);
    } else {
      setClickedOutside(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    if (clickedOutside) {
      let a = [...msgArr];
      a = a.map((item) => {
        return '';
      });
      setMsgArr(a);
      setAnswers([]);
      setCurrentIndex(0);
      setOpen(false);
    }
  }, [clickedOutside]);

  const spellCheck = (slide, index) => {
    setLoading(true);
    props
      .checkSpelling({ data: msgArr[slide.inputInt] })
      .then((res) => {
        if (res?.data && res.data.status === 200) {
          let spellchecked = res.data.data;
          checkAnswer(slide, spellchecked.toLowerCase(), index);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const checkAnswer = (slide, item, index) => {
    const ans = [...answers];

    let obj = {
      question: slide.text,
      isCorrect:
        parseInt(slide.correct, 10) === parseInt(item.id, 10) ||
        slide.correct.toLowerCase() === item,
      answer: item,
    };
    if (obj.isCorrect) {
      setCorrect(correct + 1);
    }
    if (slides.length - 1 !== index) {
      ans.push(obj);
      setAnswers((answers) => [...answers, obj]);
      slider.current.goToSlide(currentIndex + 1);
    } else if (ans.length !== slides.length) {
      ans.push(obj);
      setAnswers((answers) => [...answers, obj]);
    }
    if (ans.length === slides.length) {
      let corr = 0;
      let str = [];
      let a = ans.map((item) => {
        if (item.isCorrect) {
          corr++;
        }
        if (item?.answer?.value) {
          str.push(item.answer.value);
        } else {
          str.push(item.answer);
        }
      });

      props.sendResponse(
        {
          value: str.join(),
          next_q: current.action.on_done,
        },
        `${corr} / ${ans.length} Rätt`,
        { ...current, next_q: current.action.on_done }
      );
    }
  };
  if (!slides) {
    return null;
  }
  return (
    <div ref={myRef} className={!open ? 'quizModule' : 'quizModule open'}>
      {open && (
        <>
          <Carousel
            dragging={false}
            swiping={false}
            ref={slider}
            //withoutControls={true}
            cellAlign="center"
            renderTopCenterControls={({ currentSlide }) => {
              return null;
            }}
            afterSlide={(slideIndex) => setCurrentIndex(slideIndex)}
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide }) => null}
            renderCenterRightControls={({ nextSlide }) => null}
          >
            {slides.map((slide, index) => {
              return (
                <div
                  key={'slide' + index}
                  className="quizHolder"
                  style={{
                    width: useMobile ? '100%' : width,
                  }}
                >
                  <p className="title">
                    <b className="nr">{index + 1}.</b> {slide.text}
                  </p>
                  {slide?.pic && (
                    <div
                      className="picholder"
                      style={{
                        backgroundImage: `url(${slide.pic})`,
                        width: useMobile ? '100%' : width,
                      }}
                    ></div>
                  )}
                  <div className="btnHolder">
                    {slide?.options &&
                      slide.options.map((btn, ind) => {
                        return (
                          <Button
                            key={`btn_${index}_${ind}`}
                            className="quizBtn noselect"
                            onClick={() => {
                              checkAnswer(slide, btn, index);
                            }}
                          >
                            {btn.text}
                          </Button>
                        );
                      })}
                  </div>
                  {slide?.isInput && (
                    <div
                      className="inHolder"
                      style={{ opacity: !loading ? 1 : 0.4 }}
                    >
                      <Input
                        key={'input' + slide.inputInt}
                        autoComplete="off"
                        style={{
                          pointerEvents: 'auto',
                          marginRight: '10px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.currentTarget.focus();
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.keyCode === 13 &&
                            !loading &&
                            msgArr[slide.inputInt].length > 0
                          ) {
                            spellCheck(slide, index);
                          }
                        }}
                        onChange={(e) => {
                          if (!loading) {
                            let marr = [...msgArr];
                            marr[slide.inputInt] = e.target.value;
                            setMsgArr(marr);
                          }
                        }}
                        value={msgArr[slide.inputInt]}
                      />
                      <Submit
                        className="square"
                        style={
                          msgArr[slide.inputInt].length === 0
                            ? { opacity: 0.4 }
                            : {}
                        }
                        onClick={() => {
                          if (msgArr[slide.inputInt].length > 0 && !loading) {
                            spellCheck(slide, index);
                          }
                        }}
                      >
                        <img
                          src={require('../../../assets/send.svg').default}
                        />
                      </Submit>
                    </div>
                  )}
                </div>
              );
            })}
          </Carousel>
          <div className="dottholder">
            {slides.map((pic, index) => {
              return (
                <div
                  key={'dott' + index}
                  className={currentIndex === index ? 'dott active' : 'dott'}
                ></div>
              );
            })}
          </div>
        </>
      )}
      {!open && (
        <Button
          className="noselect"
          //style={msg.length === 0 ? { opacity: 0.4 } : {}}
          onClick={() => {
            setOpen(true);
            // props.sendResponse(selected, selected.text, current);
          }}
        >
          {btn}
          <img
            style={{ marginLeft: 10 }}
            src={require('../../../assets/send.svg').default}
          />
        </Button>
      )}
    </div>
  );
}
export default QuizModule;
